export default {
    data() {
        return {
            clipboardSupported: false
        };
    },

    methods: {
        async checkClipboardSupport() {
            try {
                if (navigator.permissions) {
                    const permissions = await navigator.permissions.query({ name: 'clipboard-read' });
                    if (permissions.state === 'granted' || permissions.state === 'prompt') {
                        this.clipboardSupported = true;
                    }
                }
            } catch (e) {
                if (e instanceof TypeError) {
                    // This is needed because safari doesn't have proper permissions for clipboard for now.
                    this.clipboardSupported = navigator.clipboard?.readText && navigator.clipboard?.writeText;
                }
            }
        },

        async copyToClipboard(copiedValue) {
            try {
                await navigator.clipboard.writeText(JSON.stringify(copiedValue, null, ' '));

                this.$notify({
                    group: 'flashes',
                    text: this.$i18n.t('general.copy_success').toString(),
                    type: 'success'
                });
            } catch (e) {
                this.$notify({
                    group: 'flashes',
                    text: this.$i18n.t('general.copy_failed').toString(),
                    type: 'error'
                });
            }
        },

        async readFromClipboard(properties) {
            try {
                const pastedJSON = await navigator.clipboard.readText();
                const result = JSON.parse(pastedJSON);

                if (properties.some(property => !result[property])) {
                    throw new Error('Clipboard data is not valid');
                }
                this.$notify({
                    group: 'flashes',
                    text: this.$i18n.t('general.paste_success').toString(),
                    type: 'success'
                });
                return result;
            } catch (e) {
                this.$notify({
                    group: 'flashes',
                    text: this.$i18n.t('general.paste_failed').toString(),
                    type: 'error'
                });
            }
        }
    }
};
