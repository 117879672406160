/**
 * @typedef {Object} CellConfig
 * @property {string} field the field to render
 * @property {string} [label] the human readable label for the field
 * @property {string} [sortable] the sortable string for the BE to sort by
 * @property {object} [component] the component to render the field
 * @property {function} [format] the function to format the field
 */
/**
 * Renders the cell with the given field and item
 *
 * @param {CellConfig} cellConfig the configuration for the cell
 * @param {object} item the FP typed item
 *
 * @returns {string} a human readable version of the given field
 */
export function render(cellConfig, item) {
    if (cellConfig.format) {
        if (typeof cellConfig.format === 'function') {
            return cellConfig.format(item);
        } else {
            console.warn('cellConfig.format is not a function', cellConfig.format);
        }
    }

    return item[cellConfig.field] || '×';
}

/**
 * Determines the CSS classes to print on the given cell
 *
 * @param {CellConfig} cellConfig the configuration for the cell
 * @param {object} item the FP typed item
 *
 * @returns {string[]} a list of CSS classes to use for the cell
 */
export function cellClasses(cellConfig, item) {
    const classes = [cellConfig.cssClass || cellConfig.field];
    if (item.status) {
        classes.push(item.status);
    }
    return classes;
}

/**
 * Gets the sorting classes for the given cell
 *
 * @param {CellConfig} cellConfig the configuration for the cell
 * @param {string} sort the current sort direction
 * @param {string} sortField the current sort field
 *
 * @returns {string[]} a list of sorting css classes
 */
export function getCellSortClass(cellConfig, sort, sortField) {
    if (!cellConfig.sortable) {
        return;
    }

    const classes = ['sortable'];

    if (cellConfig.sortable === sortField) {
        classes.push(`sort-${sort.toLowerCase()}`);
    }

    return classes;
}

/**
 * Sets the sortable field and reload the collection
 *
 * @param {CellConfig} cellConfig the configuration for the cell
 * @param {string} sort the current sort direction
 * @param {string} sortField the current sort field
 */
export function setSortable(cellConfig, sort, sortField) {
    if (!cellConfig.sortable) {
        return { sort, sortField };
    }

    sort = sortField === cellConfig.sortable && sort === 'ASC' ? 'DESC' : 'ASC';
    sortField = cellConfig.sortable;
    return { sort, sortField };
}
