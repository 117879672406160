/**
 * WARNING - THIS FILE IS AUTOGENERATED
 */

/** @const {string} COLOR_LIGHT_BASE_700 */
export const COLOR_LIGHT_BASE_700 = '#2d3339';

/** @const {string} COLOR_LIGHT_BASE_605 */
export const COLOR_LIGHT_BASE_605 = '#4f5a63';

/** @const {string} COLOR_LIGHT_BASE_600 */
export const COLOR_LIGHT_BASE_600 = '#5b6771';

/** @const {string} COLOR_LIGHT_BASE_550 */
export const COLOR_LIGHT_BASE_550 = '#71808e';

/** @const {string} COLOR_LIGHT_BASE_500 */
export const COLOR_LIGHT_BASE_500 = '#AAB3BB';

/** @const {string} COLOR_LIGHT_BASE_305 */
export const COLOR_LIGHT_BASE_305 = '#d5d9dd';

/** @const {string} COLOR_LIGHT_BASE_300 */
export const COLOR_LIGHT_BASE_300 = '#e3e6e8';

/** @const {string} COLOR_LIGHT_BASE_100 */
export const COLOR_LIGHT_BASE_100 = '#f1f2f4';

/** @const {string} COLOR_LIGHT_BASE_050 */
export const COLOR_LIGHT_BASE_050 = '#f7f7f8';

/** @const {string} COLOR_LIGHT_BASE_000 */
export const COLOR_LIGHT_BASE_000 = '#ffffff';

/** @const {string} COLOR_LIGHT_PRIMARY_600 */
export const COLOR_LIGHT_PRIMARY_600 = '#0554c5';

/** @const {string} COLOR_LIGHT_PRIMARY_500 */
export const COLOR_LIGHT_PRIMARY_500 = '#065fde';

/** @const {string} COLOR_LIGHT_PRIMARY_305 */
export const COLOR_LIGHT_PRIMARY_305 = '#1d78f9';

/** @const {string} COLOR_LIGHT_PRIMARY_300 */
export const COLOR_LIGHT_PRIMARY_300 = '#3687FA';

/** @const {string} COLOR_LIGHT_PRIMARY_100 */
export const COLOR_LIGHT_PRIMARY_100 = '#d5e6fe';

/** @const {string} COLOR_LIGHT_PRIMARY_050 */
export const COLOR_LIGHT_PRIMARY_050 = '#e4effe';

/** @const {string} COLOR_LIGHT_SUCCESS_500 */
export const COLOR_LIGHT_SUCCESS_500 = '#20934a';

/** @const {string} COLOR_LIGHT_SUCCESS_305 */
export const COLOR_LIGHT_SUCCESS_305 = '#29bd5f';

/** @const {string} COLOR_LIGHT_SUCCESS_300 */
export const COLOR_LIGHT_SUCCESS_300 = '#2DD269';

/** @const {string} COLOR_LIGHT_SUCCESS_100 */
export const COLOR_LIGHT_SUCCESS_100 = '#d4f6e0';

/** @const {string} COLOR_LIGHT_WARNING_500 */
export const COLOR_LIGHT_WARNING_500 = '#b38f00';

/** @const {string} COLOR_LIGHT_WARNING_305 */
export const COLOR_LIGHT_WARNING_305 = '#e6b800';

/** @const {string} COLOR_LIGHT_WARNING_300 */
export const COLOR_LIGHT_WARNING_300 = '#FFCC00';

/** @const {string} COLOR_LIGHT_WARNING_100 */
export const COLOR_LIGHT_WARNING_100 = '#fff4ca';

/** @const {string} COLOR_LIGHT_ALERT_500 */
export const COLOR_LIGHT_ALERT_500 = '#b36800';

/** @const {string} COLOR_LIGHT_ALERT_305 */
export const COLOR_LIGHT_ALERT_305 = '#e68600';

/** @const {string} COLOR_LIGHT_ALERT_300 */
export const COLOR_LIGHT_ALERT_300 = '#FF9500';

/** @const {string} COLOR_LIGHT_ALERT_100 */
export const COLOR_LIGHT_ALERT_100 = '#ffe9ca';

/** @const {string} COLOR_LIGHT_ERROR_500 */
export const COLOR_LIGHT_ERROR_500 = '#b20a00';

/** @const {string} COLOR_LIGHT_ERROR_305 */
export const COLOR_LIGHT_ERROR_305 = '#ff2619';

/** @const {string} COLOR_LIGHT_ERROR_300 */
export const COLOR_LIGHT_ERROR_300 = '#FF3E33';

/** @const {string} COLOR_LIGHT_ERROR_100 */
export const COLOR_LIGHT_ERROR_100 = '#ffe7e5';

/** @const {string} COLOR_LIGHT_BRAND_500 */
export const COLOR_LIGHT_BRAND_500 = '#c4380c';

/** @const {string} COLOR_LIGHT_BRAND_305 */
export const COLOR_LIGHT_BRAND_305 = '#f14712';

/** @const {string} COLOR_LIGHT_BRAND_300 */
export const COLOR_LIGHT_BRAND_300 = '#F25A2A';

/** @const {string} COLOR_LIGHT_BRAND_100 */
export const COLOR_LIGHT_BRAND_100 = '#fcdcd2';

/** @const {string} COLOR_LIGHT_ALT_1_300 */
export const COLOR_LIGHT_ALT_1_300 = '#523AF2';

/** @const {string} COLOR_LIGHT_ALT_1_100 */
export const COLOR_LIGHT_ALT_1_100 = '#e5e1fd';

/** @const {string} COLOR_LIGHT_ALT_2_500 */
export const COLOR_LIGHT_ALT_2_500 = '#c843de';

/** @const {string} COLOR_LIGHT_ALT_2_300 */
export const COLOR_LIGHT_ALT_2_300 = '#9F20B4';

/** @const {string} COLOR_LIGHT_ALT_2_100 */
export const COLOR_LIGHT_ALT_2_100 = '#f7e3fa';

/** @const {string} COLOR_DARK_BASE_700 */
export const COLOR_DARK_BASE_700 = '#cacad5';

/** @const {string} COLOR_DARK_BASE_605 */
export const COLOR_DARK_BASE_605 = '#b0b1c1';

/** @const {string} COLOR_DARK_BASE_600 */
export const COLOR_DARK_BASE_600 = '#a2a3b6';

/** @const {string} COLOR_DARK_BASE_550 */
export const COLOR_DARK_BASE_550 = '#8587a0';

/** @const {string} COLOR_DARK_BASE_500 */
export const COLOR_DARK_BASE_500 = '#54556B';

/** @const {string} COLOR_DARK_BASE_305 */
export const COLOR_DARK_BASE_305 = '#3e3e4e';

/** @const {string} COLOR_DARK_BASE_300 */
export const COLOR_DARK_BASE_300 = '#323340';

/** @const {string} COLOR_DARK_BASE_100 */
export const COLOR_DARK_BASE_100 = '#272832';

/** @const {string} COLOR_DARK_BASE_050 */
export const COLOR_DARK_BASE_050 = '#23232c';

/** @const {string} COLOR_DARK_BASE_000 */
export const COLOR_DARK_BASE_000 = '#1c1c24';

/** @const {string} COLOR_DARK_PRIMARY_600 */
export const COLOR_DARK_PRIMARY_600 = '#99c2fc';

/** @const {string} COLOR_DARK_PRIMARY_500 */
export const COLOR_DARK_PRIMARY_500 = '#81b4fc';

/** @const {string} COLOR_DARK_PRIMARY_305 */
export const COLOR_DARK_PRIMARY_305 = '#4f96fb';

/** @const {string} COLOR_DARK_PRIMARY_300 */
export const COLOR_DARK_PRIMARY_300 = '#3687FA';

/** @const {string} COLOR_DARK_PRIMARY_100 */
export const COLOR_DARK_PRIMARY_100 = '#033b89';

/** @const {string} COLOR_DARK_PRIMARY_050 */
export const COLOR_DARK_PRIMARY_050 = '#03347a';

/** @const {string} COLOR_DARK_SUCCESS_500 */
export const COLOR_DARK_SUCCESS_500 = '#6cdf96';

/** @const {string} COLOR_DARK_SUCCESS_305 */
export const COLOR_DARK_SUCCESS_305 = '#42d678';

/** @const {string} COLOR_DARK_SUCCESS_300 */
export const COLOR_DARK_SUCCESS_300 = '#2DD269';

/** @const {string} COLOR_DARK_SUCCESS_100 */
export const COLOR_DARK_SUCCESS_100 = '#0e3f20';

/** @const {string} COLOR_DARK_WARNING_500 */
export const COLOR_DARK_WARNING_500 = '#ffdb4d';

/** @const {string} COLOR_DARK_WARNING_305 */
export const COLOR_DARK_WARNING_305 = '#ffd11a';

/** @const {string} COLOR_DARK_WARNING_300 */
export const COLOR_DARK_WARNING_300 = '#FFCC00';

/** @const {string} COLOR_DARK_WARNING_100 */
export const COLOR_DARK_WARNING_100 = '#4d3d00';

/** @const {string} COLOR_DARK_ALERT_500 */
export const COLOR_DARK_ALERT_500 = '#ffb54d';

/** @const {string} COLOR_DARK_ALERT_305 */
export const COLOR_DARK_ALERT_305 = '#ffa01a';

/** @const {string} COLOR_DARK_ALERT_300 */
export const COLOR_DARK_ALERT_300 = '#FF9500';

/** @const {string} COLOR_DARK_ALERT_100 */
export const COLOR_DARK_ALERT_100 = '#4d2d00';

/** @const {string} COLOR_DARK_ERROR_500 */
export const COLOR_DARK_ERROR_500 = '#ff574d';

/** @const {string} COLOR_DARK_ERROR_305 */
export const COLOR_DARK_ERROR_305 = '#ff564d';

/** @const {string} COLOR_DARK_ERROR_300 */
export const COLOR_DARK_ERROR_300 = '#FF3E33';

/** @const {string} COLOR_DARK_ERROR_100 */
export const COLOR_DARK_ERROR_100 = '#800700';

/** @const {string} COLOR_DARK_BRAND_500 */
export const COLOR_DARK_BRAND_500 = '#f69272';

/** @const {string} COLOR_DARK_BRAND_305 */
export const COLOR_DARK_BRAND_305 = '#f36d42';

/** @const {string} COLOR_DARK_BRAND_300 */
export const COLOR_DARK_BRAND_300 = '#F25A2A';

/** @const {string} COLOR_DARK_BRAND_100 */
export const COLOR_DARK_BRAND_100 = '#631c06';

/** @const {string} COLOR_DARK_ALT_1_300 */
export const COLOR_DARK_ALT_1_300 = '#523AF2';

/** @const {string} COLOR_DARK_ALT_1_100 */
export const COLOR_DARK_ALT_1_100 = '#140768';

/** @const {string} COLOR_DARK_ALT_2_500 */
export const COLOR_DARK_ALT_2_500 = '#c843de';

/** @const {string} COLOR_DARK_ALT_2_300 */
export const COLOR_DARK_ALT_2_300 = '#9F20B4';

/** @const {string} COLOR_DARK_ALT_2_100 */
export const COLOR_DARK_ALT_2_100 = '#661473';
