import { initials } from 'libs/utils/string';

/**
 * Returns the full name of a person.
 *
 * @param {Person} pax - The person object.
 * @returns {string} The full name of the person.
 */
export function getFullName(pax = {}) {
    return [pax.fname, pax.lname].filter(Boolean).join(' ').trim();
}

/**
 * Returns the initials of a person's full name.
 *
 * @param {Person} pax - The person's details.
 *
 * @returns {string} The initials of the person's full name.
 */
export function getInitials(pax = {}) {
    return initials(getFullName(pax));
}

/**
 * Returns the full position of a person.
 *
 * @param {Person} pax - The person object.
 * @param {string} [separator=' - '] - The separator used to join the company and position.
 *
 * @returns {string} The full position of the person.
 */
export function getFullPosition(pax = {}, separator = ' - ') {
    return [pax.company, pax.position].filter(Boolean).join(separator).trim();
}

/**
 * Returns the searchable string for a passenger.
 *
 * @param {Person} pax - The passenger object.
 * @param {boolean} [includeEmail=false] - Whether to include the email in the searchable name.
 * @param {boolean} [keepCase=false] - Whether to keep the case of the searchable string.
 *
 * @returns {string} The searchable string.
 */
export function getSearcheable(pax = {}, includeEmail = false, keepCase = false) {
    const parts = [pax.fname, pax.lname, pax.company, pax.position];
    if (includeEmail) {
        parts.push(pax.email);
    }

    const search = parts.filter(Boolean).join(' ').trim();

    if (keepCase) {
        return search;
    }

    return search.toLowerCase();
}
