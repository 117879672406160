// Utils
import { unixToDate } from 'libs/utils/time';
import { getSearcheable } from 'libs/utils/pax';

// Constants
import * as COLORS from 'libs/utils/global-colors';

/**
 * @typedef {Object} TimeSlots
 * @property {String} start_time
 * @property {String} end_time
 *
 * @typedef {Object} ValiditySlot
 * @property {String} start_date
 * @property {String} end_date
 * @property {TimeSlots[]} time_slots
 *
 * @example
 * [
 *      {
 *          "start_date": "2024-06-01",
 *          "end_date": "2024-06-16",
 *          "time_slots": [
 *              {
 *                  "start_time": "07:00",
 *                  "end_time": "12:30"
 *              },
 *              {
 *                  "start_time": "14:00",
 *                  "end_time": "20:00"
 *              }
 *          ]
 *      },
 *      {
 *          "start_date": "2024-06-17",
 *          "end_date": "2024-06-30",
 *          "time_slots": [
 *              {
 *                  "start_time": "00:00",
 *                  "end_time": "00:00"
 *              }
 *          ]
 *      }
 *  ]
 */

/**
 * @typedef {Object} InvaliditySlot see https://mobiscroll.com/docs/javascript/eventcalendar/api#opt-invalid
 * @property {Date | string | object} start
 * @property {Date | string | object} end
 * @property {Boolean} [allDay]
 * @property {String} [resource]
 * @property {object} [recurring] see https://mobiscroll.com/docs/javascript/core-concepts/recurrence
 */

/**
 * Builds an invalidity matrix from a validity matrix
 *
 * @param {String} resource the ID of the resource for which the invalidity matrix is built
 * @param {ValiditySlot[]} validitySlots the validity matrix
 *
 * @returns {InvaliditySlot[]} the invalidity matrix
 */
export function invalidFromValid(resource, validitySlots = []) {
    if (validitySlots.length === 0) {
        return [];
    }

    // Determine the first and last valid date boundaries
    const firstValidDate = new Date(validitySlots[0].start_date);
    firstValidDate.setHours(0, 0, 0);

    const initialInvalid = {
        start: '1970-01-01 00:00:00',
        end: '1970-01-01 23:59:50',
        resource,
        recurring: {
            repeat: 'daily',
            from: new Date(1970, 0, 1, 0),
            until: firstValidDate
        }
    };

    const lastValidDate = new Date(validitySlots[validitySlots.length - 1].end_date);
    lastValidDate.setDate(lastValidDate.getDate() + 1);

    const finalInvalid = {
        start: new Date(lastValidDate.setHours(0, 0, 0)),
        end: new Date(lastValidDate.setHours(23, 59, 50)),
        resource,
        recurring: {
            repeat: 'daily',
            from: lastValidDate,
            until: new Date(2100, 0, 1, 0)
        }
    };

    const invaliditySlots = [initialInvalid];

    for (const [index, validity] of validitySlots.entries()) {
        const next = validitySlots[index + 1];
        buildInvalidTimeSlots(invaliditySlots, validity, resource, next);
    }

    invaliditySlots.push(finalInvalid);

    return invaliditySlots;
}

/**
 * Builds invalidity slots for a single validity slot
 *
 * @param {InvaliditySlot[]} invalids the invalidity matrix
 * @param {ValiditySlot} validity the validity slot
 * @param {string} resource the ID of the resource for which the invalidity matrix is built
 * @param {ValiditySlot} next the next validity slot
 *
 * @private
 */
function buildInvalidTimeSlots(invalids, validity, resource, next) {
    const startDate = new Date(new Date(validity.start_date).setHours(0, 0, 0));
    const endDate = new Date(new Date(validity.end_date).setHours(23, 59, 59));
    const recurring = {
        repeat: 'daily',
        from: startDate,
        until: endDate
    };

    // Add initial invalidity slot between the start of the day
    // and the initial valid start time.
    addInvalid(invalids, {
        start: '00:00',
        end: validity.time_slots[0].start_time,
        resource,
        recurring
    });

    if (next) {
        const nextStartDate = new Date(new Date(next.start_date).setHours(0, 0, 0));
        addInvalid(invalids, {
            start: endDate,
            end: nextStartDate,
            resource
        });
    }

    for (const [j, timeSlot] of validity.time_slots.entries()) {
        const start = timeSlot.end_time;
        const hasNext = Boolean(validity.time_slots[j + 1]);
        let end;
        if (hasNext) {
            end = validity.time_slots[j + 1].start_time;
        } else {
            end = '23:59';
        }

        addInvalid(invalids, { start, end, resource, recurring });
    }
}

/**
 * Adds an invalidity slot to the giveninvalidity matrix
 * if it's not a whole day invalidity slot.
 *
 * @param {InvaliditySlot[]} invalids the invalidity matrix
 * @param {InvaliditySlot} invalid the validity slot
 *
 * @private
 */
function addInvalid(invalids, invalid) {
    const { start, end } = invalid;
    const fullDay = start === '00:00' && end === '23:59';

    if (start !== end && !fullDay) {
        invalids.push(invalid);
    }
}

/**
 * Computes the status of a meeting or a session.
 *
 * @param {Meeting|Session} event the meeting or session to compute the status from.
 * @param {object} resource the resource to compute the status for.
 * @param {string} resource.type the type of the resource.
 * @param {object} resource.acceptance the type of the resource.
 *
 * @returns {string} the meeting or session computed status.
 */
export function computeEventStatus(event, resource) {
    if (!event.acceptance) {
        if (resource.is_agenda) {
            return 'agenda';
        }
        return '';
    }

    const organizerAcceptance = event.acceptance[event.organizer._id];
    const organizerAccepted = organizerAcceptance === 'yes';
    const organizerDeclined = organizerAcceptance === 'no';
    const organizerPending = organizerAcceptance === 'pending';
    const somePaxAccepted = event.participants.some(p => event.acceptance[p._id] === 'yes');
    const everyPaxDeclined = event.participants.every(p => event.acceptance[p._id] === 'no');
    const somePaxPending = event.participants.some(p => event.acceptance[p._id] === 'pending');

    /**
     * Statuses definitions as per https://spotme.atlassian.net/browse/PB-3211:
     *
     * Confirmed → Meeting organizer has accepted + at least 1 invitee accepted
     * Declined → Meeting got declined by the organizer, or by all invitees
     * Invited → Meeting organizer has accepted or pending, and at least 1 invitee is pending AND no pax accepted yet
     */
    const confirmed = organizerAccepted && somePaxAccepted;
    const declined = organizerDeclined || everyPaxDeclined;
    const invited = (organizerAccepted || organizerPending) && somePaxPending && !declined && !somePaxAccepted;

    let status, meetingStatus;

    if (confirmed) {
        meetingStatus = 'yes';
    } else if (declined) {
        meetingStatus = 'no';
    } else if (invited) {
        meetingStatus = 'maybe';
    }

    if (resource.type === 'room') {
        status = meetingStatus;

    } else if (resource.type === 'person') {
        const pid = resource.id;
        const paxAcceptance = event.acceptance[pid];
        /**
         * yes → if meeting status = confirmed and the user acceptance status is accepted → dark blue
         * pending → if meeting status = confirmed and the user acceptance is pending → light blue
         *
         * if meeting status = (invited or declined), use the user acceptance status for the coloring of the tiles  →
         *      user.acceptance = pending
         *      user.acceptance = declined
         */
        status = paxAcceptance;
        if (!confirmed && paxAcceptance === 'pending') {
            status = 'maybe';
        }
    }

    return status;
}

/**
 * Reconciles the meeting organizer and participants with the provided participants array,
 * transforming the string IDs into the actual person objects.
 *
 * @param {Meeting[] & LoadedMeeting[]} meetings - The array of meetings object to reconcile.
 * @param {Person[]} participants - The array of participants to reconcile with.
 */
export function reconcileParticipants(meetings, participants) {
    for (const meeting of meetings) {
        if (typeof meeting.organizer === 'string') {
            meeting.organizer = participants.find(p => p._id === meeting.organizer);

            if (!meeting.organizer) {
                console.error('[utils/calendar] Organizer not found', meeting);
                meeting.organizer = {};
            }
        }

        if (meeting.participants.some(p => typeof p === 'string')) {
            meeting.participants = participants.filter(p => meeting.participants.includes(p._id) && p._id !== meeting.organizer._id);
        }
    }
}

/**
 * Converts a meeting object to a calendar event object.
 *
 * @param {Meeting} meeting - The meeting object.
 *
 * @returns {Object} The calendar event object.
 */
export function meetingToCalendarEvent(meeting = []) {
    try {
        const resources = [meeting.organizer._id, ...meeting.participants.map(p => p._id), meeting.meeting_location];

        // See
        // - https://mobiscroll.com/docs/javascript/eventcalendar/calendar#opt-data
        // - https://mobiscroll.com/docs/vue/eventcalendar/data-binding
        return {
            id: meeting._id,
            title: meeting.name,
            color: COLORS.COLOR_LIGHT_PRIMARY_300,
            resource: resources,
            start: unixToDate(meeting.start).toDate(),
            end: unixToDate(meeting.end).toDate(),
            origin: meeting,
            __search: getMeetingSearcheable(meeting)
        };
    } catch (error) {
        console.error('Error converting meeting to calendar event', error, meeting);
    }
}

/**
 * Converts a session object to a calendar event object.
 *
 * @param {Object} session - The session object to convert.
 *
 * @returns {Object} The calendar event object.
 */
export function sessionToCalendarEvent(session) {
    return {
        id: session._id,
        title: session.name,
        color: COLORS.COLOR_LIGHT_PRIMARY_300,
        resource: 'sessions',
        start: unixToDate(session.start),
        end: unixToDate(session.end),
        origin: session,
        is_session: true,
        __search: getMeetingSearcheable(session)
    };
}

/**
 * Returns a searchable string representation of a meeting.
 *
 * @param {Meeting} meeting - The meeting object.
 *
 * @returns {string} - The searchable string representation of the meeting.
 */
function getMeetingSearcheable(meeting) {
    const attendees = [meeting.organizer, ...(meeting.participants || [])];
    const attendeesSearch = attendees.map(a => getSearcheable(a)).join(' ').trim();
    const meetingSearch = meeting.name?.toLowerCase().trim() || '';
    return [meetingSearch, attendeesSearch].join(' ');
}

/**
 * Converts a pax or location object to a calendar resource object.
 *
 * @param {Person|Object} paxOrLocation - The pax or location object to convert.
 * @param {'person'|'room'} type - The type of the object ('person' or 'location').
 * @param {string} [color=COLORS.COLOR_LIGHT_PRIMARY_300] - The color of the calendar resource.
 *
 * @returns {Object} The converted calendar resource object.
 */
export function paxOrLocationToCalendarResource(paxOrLocation, type, color = COLORS.COLOR_LIGHT_PRIMARY_300) {
    const res = {
        id: paxOrLocation._id,
        color,
        origin: paxOrLocation,
        title: paxOrLocation.title,
        type,
        is_agenda: false,
        __search: getSearcheable(paxOrLocation)
    };

    if (type === 'person') {
        res.is_person = true;
    }

    return res;
}
